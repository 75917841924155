<template>
  <div class="wrapper">
    <b-overlay :show="isLoading" rounded="sm" @shown="onShown">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/dms/legal/department">
            {{ modulName }}</router-link
          >
          </b-breadcrumb-item>
        <b-breadcrumb-item active>{{ DepName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template>
            <b-card sub-title="">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Business Unit"
                    label-for="business-unit"
                    label-cols="4"
                    label-size="sm"
                  >
                    <b-form-input
                      style="cursor:no-drop;"
                      id="inputBusiness"
                      v-model="form.business_unit"
                      disabled
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Dept PIC"
                    label-for="department"
                    label-cols="4"
                    label-size="sm"
                  >
                    <b-form-input
                      style="cursor:no-drop;"
                      id="inputDepartment"
                      v-model="form.department"
                      disabled
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>{{ DashName }}</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right">
                  <router-link tag="a" to="/app/dms/legal/department">
                  <b-button
                    variant="info"
                    size="md"
                    >Back</b-button
                  >
                  </router-link>
                </div>
              </b-col>
            </b-row>
          </template>
          <b-row style='margin-top:20px;'></b-row>
          <b-col sm="auto" md="12">
            <div class="text-right">
              <b-button
                variant="success"
                size="sm"
                v-on:click="show = !show"
                >Add</b-button
              >
            </div>
          </b-col>
          <b-row style='margin-top:10px;'></b-row>
          <b-table
            responsive
            head-variant="dark"
            stickyColumn
            striped
            hover
            small
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="items"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
          >
             <template #cell(department)="row">
                  <span v-if="row.value !== null" @click="departmentPIC(row)"
                  style="color:blue; text-decoration: underline; cursor: pointer;"
                  >{{row.value}}</span>
              </template>

            <template v-slot:cell(actions)="{ item }">
              <b-button
              style="margin-right:5px;"
                title="Delete"
                size="xs"
                variant="outline-danger"
                v-on:click="deleteData(item.id)"
              >
                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="Edit"
                size="xs"
                variant="outline-secondary"
                v-on:click="editData(item)"
              >
                <b-icon icon="pencil-fill" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            Showing {{ pageItems }} of {{ items.length }} entries
            <b-pagination
              v-model="paging.currentPage"
              :total-rows="rows"
              :per-page="paging.perPage"
              align="right"
            >
            </b-pagination>
          </div>
          <template>
            <b-modal
              v-model="show"
              ref="my-modal"
              hide-header-close
              header-bg-variant="secondary"
              footer-bg-variant="secondary"
              hide-backdrop
              no-close-on-backdrop
              size="md"
              title="Form Business Unit"
            >
              <div id="modal">
                <!-- <form-permit-type ref="permitForm"  /> -->
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                      <b-col cols="12">
                        <!-- BusinessUnit -->
                        <validation-provider
                          name="Business Unit"
                          :rules="{ required: true }"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Business Unit<code>*</code>
                            </template>
                            <v-select
                              style="background: white;"
                              :options="businessOpt"
                              :clearable="false"
                              :disabled="DisabledForm"
                              :reduce="(data) => data.code"
                              label="name"
                              id="BusinessUnit"
                              @input="getNikUserAll()"
                              v-model="form.business_unit_pic"
                            >
                            </v-select>

                          </b-form-group>
                        </validation-provider>
                        <!-- /BusinessUnit -->

                        <!-- Nik -->
                        <validation-provider
                          name="Nik"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                          <template v-slot:label>
                            Nik<code>*</code>
                          </template>
                            <v-select
                              style="background: white;"
                              v-model="form.nik"
                              :clearable="false"
                              :disabled="DisabledForm"
                              :reduce="(data) => data.nik"
                              :options="optionsNik"
                              label="name">
                              <template slot="selected-option" slot-scope="option">
                                  <span>{{option.nik}} - {{ option.name }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                  <span> {{option.nik}} - {{ option.name }} </span>
                              </template>
                          </v-select>
                          <span id="stateNik" class="display-hidden" style='margin-top: 0.25rem;font-size: 80%;color: #f55d5d;'>
                            Isian wajib, silahkan diisi.
                            </span>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Nik -->

                        <!-- Email -->
                        <validation-provider
                          name="Email"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Email<code>*</code>
                            </template>
                            <b-form-input
                              id="inputEmail"
                              type="email"
                              v-model="form.email"
                              :state="getValidationState(validationContext)"
                              trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="inputEmail">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Email -->

                      </b-col>
                    </b-row>
                    <b-row class="float-right">
                      <b-button class="mr-3" variant="success" type="submit"
                        >Save</b-button
                      >
                      <b-button class="mr-3" variant="warning" @click="cancel()"
                        >Cancel</b-button
                      >
                    </b-row>
                  </b-form>
                </validation-observer>
              </div>
              <template #modal-footer>
                <div class="h-300 bg-secondary"></div>
              </template>
            </b-modal>
          </template>
        </b-card>
      </b-container>
      <template #overlay>
        <div ref="loadings" class="text-center">
          <b-icon
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { fnGET, fnPOST } from "../../../../../api/api-methods";
import { APIPATH_DMS, APIPATH_BBIAPPS } from "../../../../../api/api-paths";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
const now = new Date();
export default {
  data() {
    return {
      DashName: "Department PIC",
      formName: "Department",
      modulName: "Dashboard",
      DepName: "Department PIC",
      items: [],
      fields: [
        { key: "actions", label: "actions" },
        { key: "nik", label: "nik" },
        { key: "email", label: "email" },
        { key: "created_by", label: "Created By" },
        {
          key: "created_at",
          label: "Created At",
          formatter: this.setDateFormat,
        },
        { key: "update_by", label: "Update By" },
        {
          key: "update_at",
          label: "Update At",
          formatter: this.setDateFormat,
        },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: false,
      show: false,
      form: {
        idData:null,
        mapping_id:null,
        business_unit:null,
        department:null,
        business_unit_pic:null,
        nik: null,
        email: null,
        created_by: null,
        update_by: null,
        is_active:null,
        category_submit:null,
      },
      max: now,
      filters: {
        nik: "",
        email: "",
        created_by: "",
        update_by: "",
      },
      DisabledForm:false,
      filter: null,
      businessOpt:[],
      optionsNik:[],
      includeFiled: ["nik", "email", "created_by", "update_by"],
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.items) {
        return this.items.length;
      }
    },
    pageItems() {
      if (this.items.length == 0) {
          return this.items.length
      } else {
        var totalPage = Math.ceil(this.rows / this.paging.perPage);
        if (this.paging.currentPage === totalPage) {
          return (
            (this.rows % this.paging.perPage) +
            this.paging.perPage * totalPage -
            this.paging.perPage
          );
        } else {
          return this.paging.perPage * this.paging.currentPage;
        }
      }
    },
    filterReminder() {
      var nik = this.filters.nik;
      var email = this.filters.email;
      var created_by = this.filters.created_by;
      var update_by = this.filters.update_by;

      return this.items.filter(function (item) {
        var filtereds = true;
        if (nik && nik.length > 0) {
          filtereds = item.nik === nik;
        }
        if (filtereds) {
          if (email && email.length > 0) {
            filtereds = item.email === email;
          }
        }
        if (filtereds) {
          if (update_by && update_by.length > 0) {
            filtereds = item.update_by === update_by;
          }
        }
        if (filtereds) {
          if (created_by && created_by.length > 0) {
            filtereds = item.created_by === created_by;
          }
        }
        // if (filtereds) {
        //   if (status === false) {
        //     filtereds = item.is_active === false;
        //   } else {
        //     filtereds = item.is_active === true;
        //   }
        // }
        return filtereds;
      });
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    setDateFormat(value) {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    LoadData() {
      this.form.category_submit = 'get'

      fnPOST(APIPATH_DMS.DepartmentPIC, this.form)
      .then((rsp) => {
        this.cancel();
        // console.log(rsp.data.payload)
        if (rsp.data.payload == 'No Data') {
          this.items = []
          this.showNotification({
              type: "info",
              message: "Data Not Found",
          });
        } else {
          this.items = rsp.data.payload
        }
      })
      .catch((err) => {
        this.showNotification({
          type: "error",
          message: err,
        });

      })
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getBusinessUnit() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "businessOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getNikUserAll() {
      this.optionsNik = []
      Vue.set(this, "isLoading", true);

      fnPOST(APIPATH_BBIAPPS.userAll, {
        factory:this.form.business_unit_pic,
      })
        .then((rsp) => {
          // console.log(rsp.data.payload)
          Vue.set(this, "optionsNik", rsp.data.payload);
          Vue.set(this, "isLoading", false);
        })
        .catch((err) => {
          Vue.set(this, "isLoading", false);
          return err;
        });
    },

    cancel() {
      this.$refs["my-modal"].hide();
      this.DisabledForm = false
      this.form.idData = null;
      this.form.nik = null;
      this.form.email = null;
      this.form.created_by = null;
      this.form.update_by = null;
      this.form.is_active = null;
      this.form.category_submit = null;

    },
    onSubmit() {
      this.DisabledForm = true
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Save",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.form.category_submit = 'created'
            this.form.created_by = this.userData.fullname

            if (this.form.idData != null) {
              this.form.category_submit = 'update'
              this.form.update_by = this.userData.fullname
            }

            if (this.form.business_unit_pic == null || this.form.business_unit_pic == "") {
              return false;
            }
            if (this.form.nik == null || this.form.nik == "") {
              document.getElementById("stateNik").classList.remove("display-hidden");
              setTimeout(() => {
                document.getElementById("stateNik").classList.add("display-hidden");
              }, 3000);
              return false;
            }
            if (this.form.email == null || this.form.email == "") {
              return false;
            }

            Vue.set(this, "isLoading", true);

              fnPOST(APIPATH_DMS.DepartmentPIC, this.form)
                .then((rsp) => {
                  this.showNotification({
                    type: "success",
                    message: "Successfully Submitted",
                  });
                  Vue.set(this, "isLoading", false);

                  this.LoadData()
                })
                .catch((err) => {
                  // console.log(err.response.data.message)
                  this.showNotification({
                    type: "error",
                    message: err.response.data.message,
                  });
                  Vue.set(this, "isLoading", false);
                  return err;
                });
          }
        })
    },

    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.set(this, "isLoading", true);
            this.form.category_submit = 'delete'
            this.form.update_by = this.userData.fullname
            this.form.idData = id

            fnPOST(APIPATH_DMS.DepartmentPIC, this.form)
            .then((rsp)=> {
              this.showNotification({
                type: "success",
                message: "Successfully Updated",
              });
              Vue.set(this, "isLoading", false);
              this.LoadData()
            })
            .catch((err)=> {
              this.showNotification({
                type: "error",
                message: err,
              });
              Vue.set(this, "isLoading", false);

            })

          }
        });
    },
    editData(item) {
      console.log(item)
      Vue.set(this, "show", true);
      Vue.set(this.form, "idData", item.id);
      Vue.set(this.form, "mapping_id", item.mapping);
      Vue.set(this.form, "email", item.email);
      Vue.set(this.form, "nik", item.nik);
      this.DisabledForm = true
    },
    setUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
      Vue.set(this.form, "update_by", this.userData.fullname);
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      this.$refs.loadings.focus();
    },
    setData() {
      this.form.mapping_id        = this.$route.params.idData
      this.form.business_unit     = this.$route.params.business_unit
      this.form.department        = this.$route.params.department
      this.form.business_unit_pic = this.$route.params.business_unit
      if (!this.$route.params.idData) {
        this.$router.push({ name: 'DepartmentMapping' })
      } else {
        this.setUserData();
        this.getBusinessUnit();
        this.getNikUserAll();
        this.LoadData();
      }

    },

  },
  mounted() {
    this.setData()
  }
};
</script>